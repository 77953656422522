import { AuthenticateUserRequestInterface, DeviceTypes, EmailCodeRequestInterface, EmailVerifyRequestInterface, ResetPasswordRequestInterface, SetupCompanyRequestInterface } from "@gryd/library";
import { DeviceUUID } from "device-uuid";
import { config } from "../config";
import Cookies from 'js-cookie';

export class AppService {
    deviceUuid: DeviceUUID = new DeviceUUID();
    company: any;
    loggedIn: boolean = false;
    token?: string | null;

    constructor() {
        this.token = Cookies.get('token');
        // this.setupIntercom();
    }

    async waitForDetails() {
        return true;
        // if(this.loggedIn) {
        //     return true;
        // }

        // if(!this.token) {
        //     return false;
        // }

        // try {
        //     await this.refreshUser();
        //     this.loggedIn = true;
        //     return true;
        // } catch (e) {
        //     this.loggedIn = false;
        //     return false;
        // }
    }

    getCompanyUser() {
        return {
            user: null,
            company: this.company,
        }
    }

    logout() {
        this.token = null;
        Cookies.remove('token');

        (window as any).Intercom('shutdown');
        this.setupIntercom();
    }

    async setupIntercom() {
       (window as any).Intercom('boot', {
            app_id: 'so1naseb'
        });
    }
    
    async updateIntercom(data?: any) {
        const intercomData: any = (data.user && data.company) ? {
            email: data.user.email,
            user_id: data.user.uuid,
            user_hash: data.user.hashedUuid,
            name: data.user.forename + ' ' + data.user.surname,
            phone: data.user.mobileNumber,
            created_at: data.user.createdAt,
            company: {
                company_id: data.company.uuid,
                name: data.company.name 
            }
        } : { };

       (window as any).Intercom('update', intercomData);
    }

    async getClient(clientUuid: string) {
        const response = await fetch(config.url.API_URL + 'clients/'+clientUuid, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        })

        if(!response.ok) {
            throw new Error('Failed')
        }   

        const data = await response.json();
        data.imageUrl = config.url.API_URL + 'clients/'+clientUuid+'/image';

        return data;
    }

    async getClientVehicle(clientUuid: string, vrm: string) {
        const response = await fetch(config.url.API_URL + 'clients/'+clientUuid+'/vrm/'+vrm, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        })

        if(!response.ok) {
            throw new Error('Failed')
        }   

        const data = await response.json();

        return data;
    }

    async submitServiceCredentials(clientUuid: string, vrm: string, credentials: any) {
        try {
            const response = await fetch(config.url.API_URL + `clients/${clientUuid}/vrm/${vrm}/credentials`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(credentials), // body data type must match "Content-Type" header
            });

            if(!response.ok) {
                throw new Error('Failed')
            }

            const data = await response.json();

            return data;
        } catch(e) {
            return false;
        }
    }

    async demoPOSTRequest(token: string, password: string) {
        try {
            const response = await fetch(config.url.API_URL + 'user/reset', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    password,
                } as ResetPasswordRequestInterface), // body data type must match "Content-Type" header
            });

            if(!response.ok) {
                throw new Error('Failed')
            }

            const data = await response.json();

            return data;
        } catch(e) {
            return false;
        }
    }
}