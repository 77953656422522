import { BottomNavigation, BottomNavigationAction, Icon, Link } from "@mui/material";
import logo from '../logo-G.svg';


export default function FooterComponent() {

    return (
        <BottomNavigation sx={{ position: '', bottom: 0, width: 1.0, backgroundColor: 'transparent' }}
            showLabels
        >
            <Link href="https://www.gryd.org" target="_blank">
                <BottomNavigationAction label="Gryd"icon={<Icon>
                    <img src={logo} height="100%" />
                </Icon>} />
            </Link>
        </BottomNavigation>
    )
}