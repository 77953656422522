import { styled, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import FooterComponent from './components/footer.component';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function Layout() {
    return (
        <>
            <main style={{height:"100vh"}}>
                <Outlet />
                <FooterComponent />
            </main>
        </>
    );
}

export default Layout;