import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './Home.css';
import logo from '../../logo-G.svg';

import { appService } from '../../App';
import { Box, Card, CardContent, CardHeader, CardMedia, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Switch, TextField, Typography, useTheme } from '@mui/material';
import { useNavigate, useSearchParams } from "react-router-dom";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import HighlightIcon from '@mui/icons-material/Highlight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import Link from '../../components/link.component';
import { ConnectedServiceCredentialsType } from '@gryd/library';

enum FlowState {
  LOADING,
  CLIENT_ERROR,
  CLIENT_FOUND,
  VEHICLE_FOUND,
  VEHICLE_FOUND_USERNAME_PASSWORD,
  VEHICLE_NOT_FOUND,
  VEHICLE_INCOMPATIBLE,
}

function Home() {
  const navigate = useNavigate();

  const [queryParameters] = useSearchParams()
  const clientUuid: string = queryParameters.get('client') as string;

  const [flowState, setFlowState] = useState<any>(FlowState.LOADING)
  const [permissionState, setPermissionState] = useState<any>({
    horn: true,
    lights: true,
    location: true,
    climate: true,
    lock: true
  })
  const [clientData, setClientData] = useState<any>(null)
	const [vrmValue, setVRMValue] = useState<any>(null)
	const [credentialsValue, setCredentialsValue] = useState<any>({
    username: null,
    password: null
  })

  const [connectedVehicleData, setConnectedVehicleData] = useState<any>(null)

  function setCredentials(username?: string, password?: string) {
    setCredentialsValue({
      username: username ? username : credentialsValue.username,
      password: password ? password : credentialsValue.password,
    })
  }

  function handleToggle(value: string) {
    permissionState[value] = !permissionState[value];
    setPermissionState({ ...permissionState });
  };
  
  const [vehicleData, setVehicleData] = useState<any>(null)

  useEffect(() => {
    getClient();
  }, []);

  async function getClient() {
    if(clientUuid) {
      const data = await appService.getClient(clientUuid)
      setClientData(data);
      setFlowState(FlowState.CLIENT_FOUND);
      return data;
    }

    setFlowState(FlowState.CLIENT_ERROR);
    return null;
  }

  async function handleVRMSubmit(e: any) {
    e.preventDefault();

    if(vrmValue) {
      setFlowState(FlowState.LOADING);

      try {
        const data = await appService.getClientVehicle(clientUuid, vrmValue.trim().toUpperCase());
        setVehicleData(data);

        if(data.service) {
          if(data.service.strategy === ConnectedServiceCredentialsType.UsernamePasswordCredentials) {
            setFlowState(FlowState.VEHICLE_FOUND_USERNAME_PASSWORD);
          } else {
            setFlowState(FlowState.VEHICLE_FOUND);
          }
        } else {
          setFlowState(FlowState.VEHICLE_INCOMPATIBLE);
        }
      } catch(e) {
        setFlowState(FlowState.VEHICLE_NOT_FOUND);
      }
    }
  }

  async function handleCredentialSubmit(e: any) {
    e.preventDefault();

    if(credentialsValue.username && credentialsValue.password) {
      setFlowState(FlowState.LOADING);

      try {
        const data = await appService.submitServiceCredentials(clientUuid, vrmValue.trim().toUpperCase(), {
          username: credentialsValue.username,
          password: credentialsValue.password
        });
        
        setConnectedVehicleData(data);
        setFlowState(FlowState.VEHICLE_FOUND);

      } catch(e) {
        setFlowState(FlowState.VEHICLE_NOT_FOUND);
      }
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: 'calc(100% - 56px)', paddingTop: '20px', paddingBottom: '20px' }}
    >
      <Card variant="outlined" sx={{ width: '90%', maxWidth: 500 }}>

          {flowState == FlowState.LOADING && (
            <CardContent>
              <center>
                <CircularProgress />
                <p>Setting up...</p>
              </center>
            </CardContent>
          )}

          {flowState == FlowState.CLIENT_ERROR && (
            <CardContent>
              <center>
                <Typography gutterBottom variant="h5" component="div">
                  Client Not Found
                </Typography>
              </center>
            </CardContent>
          )}

          {flowState == FlowState.CLIENT_FOUND && (
            <CardContent>
              <center>
                <img src={clientData?.imageUrl ? clientData.imageUrl : logo} width="50px" className="App-logo" alt="logo" />
                <p>{clientData?.name} wants to Connect to your vehicle via Gryd</p>
              </center>

              <hr color='#333' />

              <List>
                <ListItem disablePadding>
                    <ListItemIcon>
                      <VerifiedUserIcon />
                    </ListItemIcon>

                  <ListItemText
                    primary="Always secure"
                    secondary={
                      <React.Fragment>
                        {/* <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Your information is handled securely with end-to-end encryption.
                        </Typography> */}
                        Your information is handled securely with end-to-end encryption.
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                      <VisibilityIcon />
                    </ListItemIcon>

                  <ListItemText
                    primary="Configurable permissions"
                    secondary={
                      <React.Fragment>
                        {/* <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Your information is handled securely with end-to-end encryption.
                        </Typography> */}
                        Share only what you agree to with {clientData?.name}.
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>

              <hr color='#333' />
              
              <center>
                <p>
                  
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          By clicking "Continue" you agree to the
                          <br />
                          <Link target='_blank' href='https://www.gryd.org/policy#privacy'>Gryd End User Privacy Policy</Link>
                        </Typography>
                  </p>
              </center>

              <Box component="form" onSubmit={e => handleVRMSubmit(e)} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="vrm"
                    label="Enter your Registration"
                    type="text"
                    name="vrm"
                    onChange={(e) => setVRMValue(e.target.value)} 
                    autoFocus
                  />
                <br /> <br />
                <Button variant="contained" fullWidth type="submit">Continue</Button>
              </Box>
            </CardContent>
          )}
                    
          {flowState == FlowState.VEHICLE_FOUND && (
            <>
              <CardMedia
                sx={{ height: 180, marginTop: '20px' }}
                image={vehicleData?.vehicle?.image ? vehicleData.vehicle.image : logo}
              />
              <CardContent>
                <center>
                  <p>{vehicleData?.vehicle?.vrm} {vehicleData?.vehicle?.make} {vehicleData?.vehicle?.model} {vehicleData?.vehicle?.year}</p>
                </center>

                {connectedVehicleData && (
                <List>
                  <ListItem disablePadding disabled={!connectedVehicleData.connectedVehicle.capabilities.location}>
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>

                    <ListItemText
                      primary={
                        <>
                          Vehicle Location
                          {!connectedVehicleData.connectedVehicle.capabilities.location && (
                            <span>&nbsp;- <strong>Unavailable</strong></span>
                          )}
                        </>
                      }
                      secondary={
                        <React.Fragment>
                          Allow {clientData?.name} to retrieve the exact location of your Vehicle
                        </React.Fragment>
                      }
                    />
                    <Switch
                      edge="end"
                      disabled={!connectedVehicleData.connectedVehicle.capabilities.location}
                      onChange={() => handleToggle('location')}
                      checked={permissionState.location}
                      inputProps={{
                        'aria-labelledby': 'switch-list-label-location',
                      }}
                    />
                  </ListItem>
                  <ListItem disablePadding disabled={!connectedVehicleData.connectedVehicle.capabilities.climate}>
                      <ListItemIcon>
                        <AcUnitIcon />
                      </ListItemIcon>

                    <ListItemText
                      primary={
                        <>
                          Climate Control
                          {!connectedVehicleData.connectedVehicle.capabilities.climate && (
                            <span>&nbsp;- <strong>Unavailable</strong></span>
                          )}
                        </>
                      }
                      secondary={
                        <React.Fragment>
                          Allow {clientData?.name} to activate your Climate Control 

                    {permissionState.location ? 'YES' : 'NO§'}
                        </React.Fragment>
                      }
                    />
                    <Switch
                      edge="end"
                      disabled={!connectedVehicleData.connectedVehicle.capabilities.climate}
                      onChange={() => handleToggle('climate')}
                      checked={permissionState.climate}
                      inputProps={{
                        'aria-labelledby': 'switch-list-label-climate',
                      }}
                    />
                  </ListItem>
                  <ListItem disablePadding disabled={!connectedVehicleData.connectedVehicle.capabilities.horn}>
                      <ListItemIcon>
                        <VolumeUpIcon />
                      </ListItemIcon>

                    <ListItemText
                      primary={
                        <>
                          Horn
                          {!connectedVehicleData.connectedVehicle.capabilities.horn && (
                            <span>&nbsp;- <strong>Unavailable</strong></span>
                          )}
                        </>
                      }
                      secondary={
                        <React.Fragment>
                          Allow {clientData?.name} to activate your Horn
                        </React.Fragment>
                      }
                    />
                    <Switch
                      edge="end"
                      disabled={!connectedVehicleData.connectedVehicle.capabilities.horn}
                      onChange={() => handleToggle('horn')}
                      checked={permissionState.horn}
                      inputProps={{
                        'aria-labelledby': 'switch-list-label-horn',
                      }}
                    />
                  </ListItem>
                  <ListItem disablePadding disabled={!connectedVehicleData.connectedVehicle.capabilities.lock}>
                      <ListItemIcon>
                        <LockPersonIcon />
                      </ListItemIcon>

                    <ListItemText
                      primary={
                        <>
                          Lock & Unlock Vehicle
                          {!connectedVehicleData.connectedVehicle.capabilities.lock && (
                            <span>&nbsp;- <strong>Unavailable</strong></span>
                          )}
                        </>
                      }
                      secondary={
                        <React.Fragment>
                          {/* <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Your information is handled securely with end-to-end encryption.
                          </Typography> */}
                          Allow {clientData?.name} to Lock & Unlock your Vehicle
                        </React.Fragment>
                      }
                    />
                    <Switch
                      edge="end"
                      disabled={!connectedVehicleData.connectedVehicle.capabilities.lock}
                      onChange={() => handleToggle('lock')}
                      checked={permissionState.lock}
                      inputProps={{
                        'aria-labelledby': 'switch-list-label-lock',
                      }}
                    />
                  </ListItem>
                  <ListItem disablePadding disabled={!connectedVehicleData.connectedVehicle.capabilities.lights}>
                      <ListItemIcon>
                        <HighlightIcon />
                      </ListItemIcon>

                    <ListItemText
                      primary={
                        <>
                          Lights
                          {!connectedVehicleData.connectedVehicle.capabilities.lights && (
                            <span>&nbsp;- <strong>Unavailable</strong></span>
                          )}
                        </>
                      }
                      secondary={
                        <React.Fragment>
                          {/* <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Your information is handled securely with end-to-end encryption.
                          </Typography> */}
                          Allow {clientData?.name} to activate your Vehicle Lights
                        </React.Fragment>
                      }
                    />
                    <Switch
                      edge="end"
                      disabled={!connectedVehicleData.connectedVehicle.capabilities.lights}
                      onChange={() => handleToggle('lights')}
                      checked={permissionState.lights}
                      inputProps={{
                        'aria-labelledby': 'switch-list-label-lights',
                      }}
                    />
                  </ListItem>
                </List>
                )}
                <hr color='#333' />
              </CardContent>
            </>
          )}
                    
          {flowState == FlowState.VEHICLE_FOUND_USERNAME_PASSWORD && (
            <>
              <CardMedia
                sx={{ height: 180, marginTop: '20px' }}
                image={vehicleData?.vehicle?.image ? vehicleData.vehicle.image : logo}
              />
              <CardContent>
                <center>
                  <p>{vehicleData?.vehicle?.make} {vehicleData?.vehicle?.model} {vehicleData?.vehicle?.year} - {vehicleData?.vehicle?.vrm}</p>
                </center>

                <hr color='#333' />

                <Box component="form" onSubmit={e => handleCredentialSubmit(e)} sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      inputProps={{
                        autoComplete: 'one-time-code',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                      id="username"
                      label="Enter your Email/Username"
                      type="text"
                      name="username"
                      onChange={(e) => setCredentials(e.target.value, undefined)} 
                      autoFocus
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      inputProps={{
                        autoComplete: 'one-time-code',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                      id="password"
                      label="Enter your Password"
                      type="password"
                      name="password"
                      onChange={(e) => setCredentials(undefined, e.target.value)} 
                    />
                  <br /> <br />
                  <Button variant="contained" fullWidth type="submit">Continue</Button>
                </Box>
              </CardContent>
            </>
          )}

          {flowState == FlowState.VEHICLE_NOT_FOUND && (
            <CardContent>
              <center>
                <Typography gutterBottom variant="h5" component="div">
                  Vehicle Not Found
                </Typography>
              </center>
            </CardContent>
          )}

          {flowState == FlowState.VEHICLE_INCOMPATIBLE && (
            <>
              <CardMedia
                sx={{ height: 160 }}
                image={vehicleData?.vehicle?.image ? vehicleData.vehicle.image : logo}
              />
              <CardContent>
                <center>
                  <Typography gutterBottom variant="h5" component="div">
                    Vehicle Not Compatible
                  </Typography>

                  <p>{vehicleData?.vehicle?.make} {vehicleData?.vehicle?.model} {vehicleData?.vehicle?.year} - {vehicleData?.vehicle?.vrm}</p>
                </center>
              </CardContent>
            </>
          )}

      </Card>
    </Grid>
  );
}

export default Home;
