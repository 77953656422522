// Constants.js
const prod = {
    url: {
        API_URL: 'https://connect.gryd.org/api/connect/',
    }
};
const dev = {
    url: {
        API_URL: 'http://localhost:8000/api/connect/'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;