import { defer, useLoaderData, Outlet, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Layout from "./Layout";
import NoPage from "./pages/not-found/NoPage";
import { AppService } from "./services/app.service";
import './App.css';
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import { theme, themeOptions } from "./theme";

export const appService = new AppService();

const ProtectRoute = ({ loggedInCheck, redirect }: { loggedInCheck: boolean, redirect: string }) => {
    const data = useLoaderData();

    if(loggedInCheck == appService.loggedIn) {
      return <Outlet />;
    } else {
      return <Navigate to={redirect} replace={true} />
    }
};

const loginLoader = async () => {
  const result = await appService.waitForDetails();
  return defer({
    loggedIn: result
  })
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} loader={loginLoader}>
      <Route element={<ProtectRoute redirect="/dashboard" loggedInCheck={false} />}>
        <Route 
          index 
          element={
            <Home />
          } 
        />
      </Route>
      <Route path="*" element={<NoPage />} />
    </Route>
  )
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: themeOptions.palette?.background?.default },
        }}
      />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;